import {
  CreateGameParams,
  GetGameByIdParamsTypes,
  GameTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllGameService = async (params: BaseFilterParams)
  : Promise<APIPaginationResponse<GameTypes[]>> => {
  const res = await axiosInstance.get('booths', { params });
  return res.data;
};

export const getGameByIdService = async ({ id }: GetGameByIdParamsTypes)
  : Promise<GameTypes> => {
  const res = await axiosInstance.get(`booths/${id}`);
  return res.data.data;
};

export const createGameService = async (params: CreateGameParams):
  Promise<{ id: number }> => {
  const res = await axiosInstance.post('booths', params);
  return res.data.data;
};

export const updateGameService = async (id?: number, params?: CreateGameParams):
  Promise<{ id: number }> => {
  const res = await axiosInstance.put(`booths/${id}`, params);
  return res.data.data;
};

import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Modal, Row, Space, Spin, Typography
} from 'antd';
import Password from 'antd/es/input/Password';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation, useQuery
} from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import {
  createIpadService,
  getIpadByIdService,
  updateIpadService,
  updatePasswordIpadService
} from 'common/services/ipads';
import { IpadRequestTypes, UpdateIpadRequestTypes } from 'common/services/ipads/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import {
  salePersonCreateData, salePersonEditData, salePersonUpdatePassword
} from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

type IpadPasswordFormTypes = {
  password: string,
};

const defaultValues: IpadRequestTypes = {
  username: '',
  fullName: '',
  password: '',
  boothId: undefined
};

const EditSalePerson: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  /* Selectors */
  const rolesUser = useAppSelector((state) => state.auth.roles);

  /* States */
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /* States */
  const idParams = Number(searchParams.get('id'));

  const schema = idParams ? salePersonEditData : salePersonCreateData;

  /* React-hook-form */
  const method = useForm<IpadRequestTypes>({
    resolver: yupResolver(schema),
    defaultValues
  });
  const passwordMethod = useForm<IpadPasswordFormTypes>({
    resolver: yupResolver(salePersonUpdatePassword),
    mode: 'onChange',
    defaultValues: {
      password: '',
    }
  });

  /* Queries */
  const { data: ipadByIdData, isLoading } = useQuery(
    ['getIpadById', idParams],
    () => {
      if (idParams) {
        return getIpadByIdService(idParams);
      }
      return undefined;
    },
    {
      enabled: !!idParams,
      onSuccess(ipadRes) {
        if (ipadRes) {
          const {
            ipad, booth
          } = ipadRes.data;
          const objDefault = {
            username: ipad.username,
            password: '',
            fullName: ipad.fullName,
            boothId: booth?.id
          };
          method.reset(objDefault);
        } else {
          method.reset(defaultValues);
        }
      },
    }
  );

  const { mutate: updateIpadByIdMutate, isLoading: updateLoading } = useMutation(
    ['updateIpad', idParams],
    async (params: {
      id: number;
      data: UpdateIpadRequestTypes
    }) => updateIpadService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createIpadMutate, isLoading: createLoading } = useMutation(
    'createIpad',
    createIpadService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.IPAD_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updatePasswordIpadMutate, isLoading: updatePasswordLoading } = useMutation(
    'updatePasswordIpad',
    async (params: {
      id: number, data: {
        password: string
      }
    }) => updatePasswordIpadService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updatesError'));
      },
      onSettled: () => {
        setIsOpen(false);
      }
    }
  );

  /* Functions */
  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }
    const {
      fullName, username, boothId, password
    } = method.getValues();

    if (idParams) {
      updateIpadByIdMutate({
        id: idParams,
        data: {
          fullName,
          ...(boothId && { boothId }),
        }
      });
    } else {
      createIpadMutate({
        username,
        password,
        fullName,
        ...(boothId && { boothId }),
      });
    }
  };

  const handleUpdatePassword = (data: IpadPasswordFormTypes) => {
    updatePasswordIpadMutate({
      id: idParams,
      data: {
        password: data.password
      }
    });
  };

  const submitForm = async () => {
    onSubmit();
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={idParams ? t('ipad.edit') : t('ipad.create')}
        rightHeader={(
          <Space size={16}>
            <Button
              type="primary"
              disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
              loading={updateLoading || createLoading}
              onClick={method.handleSubmit(submitForm)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={isLoading || createLoading || updateLoading || updatePasswordLoading}>
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <FormProvider {...method}>
                <Card type="inner">
                  <div className="site-card-border-less-wrapper">
                    <Space direction="vertical" size={12} style={{ width: '100%' }}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Typography.Text strong>
                            {t('system.username')}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="username"
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                disabled={!!idParams}
                              />
                            )}
                          />
                        </Col>
                        {/* Password  */}
                        <Col span={12}>
                          {idParams ? (
                            <Button
                              size="large"
                              onClick={() => setIsOpen(true)}
                              className="u-mt-28"
                              style={{ width: '100%' }}
                              disabled={!getPermission(
                                rolesUser,
                                roles.SALE_PERSON_CHANGE_PASSWORD
                              )}
                            >
                              {t('system.changePassword')}
                            </Button>
                          ) : (
                            <div className="p-editPageTemplate_input">
                              <Typography.Text strong>
                                {t('salePerson.password')}
                                {' '}
                              </Typography.Text>
                              <Typography.Text strong type="danger">
                                *
                              </Typography.Text>
                              <Controller
                                name="password"
                                rules={{
                                  required: t('formManagement.requiredFormField'),
                                }}
                                control={method.control}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <Password
                                      className="u-mt-8"
                                      value={value}
                                      onChange={onChange}
                                      placeholder={t('salePerson.password')}
                                      size="large"
                                    />
                                    {error && (
                                      <span
                                        className="a-input_errorMessage"
                                      >
                                        {error.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          )}
                        </Col>
                        {/* Full name  */}
                        <Col span={12}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('system.fullName')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="fullName"
                              control={method.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="large"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        {/* Booth Id */}
                        <Col span={12}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('system.boothId')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="boothId"
                              control={method.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <>
                                  <DropdownElement
                                    type="booth"
                                    placeholder={`${t('system.select')} ${t('salePerson.boothId')}`}
                                    locale="vi"
                                    value={value}
                                    onChange={onChange}
                                    size="large"
                                  />
                                  {error && (
                                    <span
                                      className="a-input_errorMessage"
                                    >
                                      {error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Space>
                  </div>
                </Card>
              </FormProvider>
              {!!idParams && (
                <Modal
                  title={<Typography.Title level={3}>{t('system.changePassword')}</Typography.Title>}
                  open={isOpen}
                  centered
                  onCancel={() => {
                    setIsOpen(false);
                    passwordMethod.setValue('password', '');
                  }}
                  onOk={() => passwordMethod.handleSubmit(handleUpdatePassword)()}
                  confirmLoading={updatePasswordLoading}
                >
                  <FormProvider {...passwordMethod}>
                    <div className="p-editPageTemplate_input u-mt-8">
                      <Typography.Text strong>
                        {t('salePerson.password')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="password"
                        control={passwordMethod.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <Password
                              className="u-mt-8"
                              value={value}
                              onChange={onChange}
                              placeholder={t('salePerson.password')}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </FormProvider>
                </Modal>
              )}
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={ipadByIdData ? dayjs(ipadByIdData?.data.ipad?.createdAt).fromNow() : ''}
                createdBy=""
                lastUpdated={ipadByIdData ? dayjs(ipadByIdData?.data.ipad?.updatedAt).fromNow() : ''}
                lastUpdatedBy=""
              />
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
};

export default EditSalePerson;

import {
  EditOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { getIpadService } from 'common/services/ipads';
import { IpadDataTypes } from 'common/services/ipads/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type IpadColumnTypes = IpadDataTypes['ipad'] & {
  boothName: string;
};

const SalePerson: React.FC<ActiveRoles> = ({
  roleCreate, roleUpdate
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateParams = useNavigateParams();
  const params = Object.fromEntries(searchParams.entries());

  /* Selectors */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    ['ipad-list', currentPage, keyword, currentView, selectedFilterList],
    () => getIpadService({
      page: currentPage,
      keyword,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      keepPreviousData: true
    }
  );

  /* Functions */

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<IpadColumnTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: IpadColumnTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (
          a: IpadColumnTypes,
          b: IpadColumnTypes
        ) => a.fullName.localeCompare(b.fullName),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_, data: IpadColumnTypes) => (
        <Typography.Text>
          {data.fullName}
        </Typography.Text>
      ),
    },
    // --- Booth Name
    {
      title: t('system.boothId'),
      dataIndex: 'boothName',
      key: 'boothName',
      sorter: {
        compare: (
          a: IpadColumnTypes,
          b: IpadColumnTypes
        ) => a.boothName.localeCompare(b.boothName),
      },
      sortDirections: ['descend', 'ascend'],
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      sorter: {
        compare: (a: IpadColumnTypes, b: IpadColumnTypes) => {
          const aDate = new Date(a.createdAt || '');
          const bDate = new Date(b.createdAt || '');
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: IpadColumnTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      sorter: {
        compare: (a: IpadColumnTypes, b: IpadColumnTypes) => {
          const aDate = new Date(a.updatedAt || '');
          const bDate = new Date(b.updatedAt || '');
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: IpadColumnTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: IpadColumnTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => roleUpdate && navigateParams(`${ROUTE_PATHS.IPAD_DETAIL}`, `id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, navigateParams]);

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('ipad', advancedFilter),
    [advancedFilter]
  );

  const tableData: IpadColumnTypes[] = useMemo(() => (
    listData?.data.map((item) => ({
      ...item.ipad,
      boothName: item.booth?.name || ''
    })) || []), [listData]);

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams(mappingFilterToQuery(selectedFilterList), { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.ipad')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => {
                navigate(`${ROUTE_PATHS.IPAD_DETAIL}`);
              }}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={setKeyword}
          noCheckbox
          tableProps={{
            initShowColumns: [
              'id',
              'name',
              'boothName',
              'createdAt',
              'updatedAt',
              'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            filterFields,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
    </>
  );
};

export default SalePerson;

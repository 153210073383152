import {
  EditOutlined, PlusOutlined
} from '@ant-design/icons';
import {
  Button, Space, Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import StatusLabel from 'common/components/StatusLabel';
import { getAllGiftService } from 'common/services/gift';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type GiftDataTypes = {
  id: number,
  name: string,
  thumbnail: string,
  image: string,
  active: boolean,
  probability: number,
  price: number,
  quotaPerDay: number,
  type: string,
  createdAt: string,
  updatedAt: string,
  usedToday?: number,
};

const GiftManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleView, roleUpdate, roleDelete,
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize, advancedFilter
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [keyword, setKeyword] = useState('');

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { data: giftData, isLoading } = useQuery(
    [
      'getAllGiftService',
      currentPage,
      currentView,
      selectedFilterList,
      keyword
    ],
    () => getAllGiftService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    { enabled: !!currentPage }
  );

  /* Variables */
  const columns: ColumnsType<GiftDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: GiftDataTypes, b: GiftDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: GiftDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (
          a: GiftDataTypes,
          b: GiftDataTypes
        ) => a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: GiftDataTypes) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.GIFT_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    // --- code
    {
      title: t('gift.price'),
      dataIndex: 'price',
      key: 'price',
      width: 100,
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: GiftDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.price}
        </Typography.Text>
      ),
    },
    {
      title: t('system.quotaPerDay'),
      dataIndex: 'quotaPerDay',
      key: 'quotaPerDay',
      width: 100,
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: GiftDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.quotaPerDay}
        </Typography.Text>
      ),
    },
    {
      title: t('system.probability'),
      dataIndex: 'probability',
      key: 'probability',
      width: 100,
      render: (_name: string, data: GiftDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.probability}
        </Typography.Text>
      ),
    },
    {
      title: t('gift.usedToday'),
      dataIndex: 'usedToday',
      key: 'usedToday',
      width: 120,
      render: (_name: string, data: GiftDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.usedToday}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: GiftDataTypes, b: GiftDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: GiftDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: GiftDataTypes, b: GiftDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: GiftDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('project.giftType'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('system.status'),
      dataIndex: 'active',
      key: 'active',
      width: 160,
      render: (_name: string, data: GiftDataTypes) => (
        <StatusLabel status={data.active ? 13 : 7} />
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: GiftDataTypes) => (
        <Space>
          <Button
            disabled={!roleView}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.GIFT_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('gift', advancedFilter),
    [advancedFilter]
  );

  const giftDataData = useMemo(() => (giftData?.data?.map((val) => ({
    ...val.giftData,
    type: val.giftData?.type ? t(`gift.${val.giftData?.type}`) : '',
    usedToday: val.usedToday || 0,
  })) || []), [giftData, t]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.gift')}
        rightHeader={(
          <Button type="primary" disabled={!roleCreate} onClick={() => navigate(`${ROUTE_PATHS.GIFT_DETAIL}`)}>
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          scrollY="70vh"
          handleSearch={setKeyword}
          isLoading={isLoading}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate,
          }}
          tableProps={{
            initShowColumns: [
              'id',
              'name',
              'active',
              // 'price',
              'quotaPerDay',
              'probability',
              'type',
              'usedToday',
              'updatedAt',
              'createdAt',
              'action'
            ],
            columns,
            pageData: giftDataData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: giftData?.meta.total || 1,
            filterFields,
            noDeleteLanguage: true,
            noBaseCol: true
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
    </>
  );
};

export default GiftManagement;

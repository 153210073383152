import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SimpleEditor } from 'common/components/Editor';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import SelectFile from 'common/components/SelectFile';
import { createGameService, getGameByIdService, updateGameService } from 'common/services/game';
import { CreateGameParams } from 'common/services/game/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { generateSlug } from 'common/utils/functions';
import { editGameData } from 'common/utils/schemas';

type GameFormTypes = {
  name: string,
  code: string,
  active: boolean,
  displayOrder: number,
  image: string,
  introduction: string,
  // isLastGame: boolean,
  // isLuckyDraw: boolean,
};

const EditGame: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  /* Hook form */
  const mainMethod = useForm<GameFormTypes>({
    resolver: yupResolver(editGameData),
    mode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
      active: false,
      displayOrder: 0,
      image: '',
      introduction: '',
      // isLastGame: false,
      // isLuckyDraw: false,
    }
  });

  /* Queries */
  const { data: gameData, isFetching: gameIsFetching } = useQuery(
    ['getGameByIdService', idParam],
    () => getGameByIdService({ id: idParam }),
    {
      enabled: !!idParam,
      onSuccess: (res) => {
        mainMethod.reset({
          name: res.boothData?.name,
          code: res.boothData?.code,
          active: res.boothData?.active,
          displayOrder: res.boothData?.displayOrder,
          image: res.boothData?.image,
          introduction: res.boothData?.introduction,
          // isLastGame: res.boothData?.active,
          // isLuckyDraw: res.boothData?.isLuckyDraw,
        });
      }
    }
  );

  const { mutate: createGameMutate, isLoading: createLoading } = useMutation(
    ['createGame'],
    createGameService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.GAME_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateGameMutate, isLoading: updateLoading } = useMutation(
    ['updateGame'],
    (data: { id: number, params: CreateGameParams }) => updateGameService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );
  /* Variables */
  const handleSubmit = async (mainData: GameFormTypes) => {
    const dataSubmit: CreateGameParams = {
      name: mainData.name || '',
      code: mainData.code || '',
      active: mainData.active || false,
      displayOrder: mainData.displayOrder,
      image: mainData.image || '',
      introduction: mainData.introduction || '',
      // isLastGame: mainData.isLastGame || false,
      // isLuckyDraw: mainData.isLuckyDraw || false,
    };

    if (idParam) {
      updateGameMutate({
        id: idParam,
        params: dataSubmit
      });
    } else {
      createGameMutate(dataSubmit);
    }
  };

  /* Render */
  return (
    <div className="p-editGame">
      <HeaderPage
        fixed
        title={idParam ? `${t('game.editGame')}` : `${t('game.addGame')}`}
        rightHeader={(
          <Button
            type="primary"
            disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
            onClick={mainMethod.handleSubmit(handleSubmit)}
            loading={createLoading || updateLoading}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            {gameIsFetching ? <Spin className="center-absolute" size="large" spinning />
              : (
                <Card
                  type="inner"
                >
                  <FormProvider {...mainMethod}>
                    <Space direction="vertical" size={12} style={{ width: '100%' }}>
                      <Row gutter={16}>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('game.name')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="name"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  placeholder={t('game.name')}
                                  onChange={(e) => {
                                    onChange(e);
                                    if (!idParam) {
                                      mainMethod.setValue('code', generateSlug(e.currentTarget.value));
                                    }
                                  }}
                                  error={error?.message}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('system.code')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="code"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  placeholder={t('system.code')}
                                  onChange={onChange}
                                  error={error?.message}
                                  disabled={!!idParam}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('system.displayOrder')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="displayOrder"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  type="number"
                                  placeholder={t('system.displayOrder')}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={error?.message}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="u-mt-8">
                            <Typography.Text strong>
                              {t('system.status')}
                            </Typography.Text>
                            {' '}
                            <div className="u-mt-8">
                              <Controller
                                name="active"
                                control={mainMethod.control}
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Controller
                              name="image"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <>
                                  <SelectFile
                                    title={t('system.image')}
                                    value={value}
                                    name="image"
                                    handleSelect={(url) => onChange(url)}
                                    handleDelete={() => onChange(undefined)}
                                    isRequired
                                  />
                                  {error && (
                                    <span className="a-input_errorMessage">
                                      {error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('system.description')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="introduction"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                              }) => (
                                <SimpleEditor
                                  value={value || ''}
                                  handleChange={(_event: any, editor: any) => {
                                    const data = editor.getData();
                                    onChange(data);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Col>
                        {/* <Col span={8}>
                          <div className="u-mt-8">
                            <Typography.Text strong>
                              {t('system.lastGame')}
                            </Typography.Text>
                            {' '}
                            <div className="u-mt-8">
                              <Controller
                                name="isLastGame"
                                control={mainMethod.control}
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Col> */}
                        {/* <Col span={8}>
                          <div className="u-mt-8">
                            <Typography.Text strong>
                              {t('system.luckyDraw')}
                            </Typography.Text>
                            {' '}
                            <div className="u-mt-8">
                              <Controller
                                name="isLuckyDraw"
                                control={mainMethod.control}
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </Space>
                  </FormProvider>
                </Card>
              )}
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={gameData ? dayjs(gameData?.boothData.createdAt).fromNow() : ''}
              createdBy=""
              lastUpdated={gameData ? dayjs(gameData?.boothData.updatedAt).fromNow() : ''}
              lastUpdatedBy=""
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditGame;

import {
  CreateGiftParams,
  GetGiftByIdParamsTypes,
  GiftTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllGiftService = async (params: BaseFilterParams)
  : Promise<APIPaginationResponse<GiftTypes[]>> => {
  const res = await axiosInstance.get('gifts', { params });
  return res.data;
};

export const getGiftByIdService = async ({ id }: GetGiftByIdParamsTypes)
  : Promise<GiftTypes> => {
  const res = await axiosInstance.get(`gifts/${id}`);
  return res.data.data;
};

export const createGiftService = async (params: CreateGiftParams):
  Promise<{ id: number }> => {
  const res = await axiosInstance.post('gifts', params);
  return res.data.data;
};

export const updateGiftService = async (id?: number, params?: CreateGiftParams):
  Promise<{ id: number }> => {
  const res = await axiosInstance.put(`gifts/${id}`, params);
  return res.data.data;
};

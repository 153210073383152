import {
  CheckCircleOutlined, CloseCircleOutlined, EditOutlined, PlusOutlined
} from '@ant-design/icons';
import {
  Button, Space, Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import StatusLabel from 'common/components/StatusLabel';
import { getAllGameService } from 'common/services/game';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type GameDataTypes = {
  id: number;
  name: string;
  code: string;
  active: boolean;
  lastGame: boolean;
  luckyDraw: boolean;
  createdAt: string;
  updatedAt: string;
};

const GameManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleView, roleUpdate, roleDelete,
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize, advancedFilter
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [keyword, setKeyword] = useState('');

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { data: boothData, isLoading } = useQuery(
    [
      'getAllGameService',
      currentPage,
      currentView,
      selectedFilterList,
      keyword
    ],
    () => getAllGameService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    { enabled: !!currentPage }
  );

  /* Variables */
  const columns: ColumnsType<GameDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: GameDataTypes, b: GameDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: GameDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (
          a: GameDataTypes,
          b: GameDataTypes
        ) => a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: GameDataTypes) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.GAME_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    // --- code
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare: (
          a: GameDataTypes,
          b: GameDataTypes
        ) => a.code.localeCompare(b.code)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: GameDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: GameDataTypes, b: GameDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: GameDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: GameDataTypes, b: GameDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: GameDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'active',
      key: 'active',
      width: 160,
      render: (_name: string, data: GameDataTypes) => (
        <StatusLabel status={data.active ? 13 : 7} />
      ),
    },
    {
      title: t('system.lastGame'),
      dataIndex: 'lastGame',
      key: 'lastGame',
      align: 'center',
      width: 160,
      render: (_name: string, data: GameDataTypes) => (
        data.lastGame
          ? <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />
          : <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 16 }} />
      ),
    },
    {
      title: t('system.luckyDraw'),
      dataIndex: 'luckyDraw',
      key: 'luckyDraw',
      width: 160,
      align: 'center',
      render: (_name: string, data: GameDataTypes) => (
        data.luckyDraw
          ? <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />
          : <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 16 }} />
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: GameDataTypes) => (
        <Space>
          <Button
            disabled={!roleView}
            icon={<EditOutlined />}
            onClick={() => roleView && navigate(`${ROUTE_PATHS.GAME_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('booth', advancedFilter),
    [advancedFilter]
  );

  const boothDataData = useMemo(() => (boothData?.data?.map((val) => ({
    id: val?.boothData?.id,
    name: val?.boothData?.name,
    code: val?.boothData?.code,
    active: val?.boothData.active,
    lastGame: val?.boothData.isLastGame,
    luckyDraw: val?.boothData.isLuckyDraw,
    createdAt: val?.boothData?.createdAt,
    updatedAt: val?.boothData?.updatedAt,
  })) || []), [boothData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.game')}
        rightHeader={(
          <Button type="primary" disabled={!roleCreate} onClick={() => navigate(`${ROUTE_PATHS.GAME_DETAIL}`)}>
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          scrollY="70vh"
          handleSearch={setKeyword}
          isLoading={isLoading}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate,
          }}
          tableProps={{
            initShowColumns: [
              'id',
              // 'code',
              'name',
              'active',
              'lastGame',
              'luckyDraw',
              'updatedAt',
              'createdAt',
              'action'
            ],
            columns,
            pageData: boothDataData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: boothData?.meta.total || 1,
            filterFields,
            noDeleteLanguage: true,
            noBaseCol: true
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
    </>
  );
};

export default GameManagement;

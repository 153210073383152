import {
  ExportOutlined,
} from '@ant-design/icons';
import {
  Button, Space, DatePicker, message, Typography, Spin
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { exportReportService, getCustomerBoothsService } from 'common/services/report';
import { downloadFile, formatDateYYYYMMDD } from 'common/utils/functions';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const defaultDateValue = [
  `${dayjs(new Date()).subtract(1, 'day').format(dateFormat)}`,
  `${dayjs(new Date()).format(dateFormat)}`,
];

type ReportDataTypes = {
  id: number,
  name: string,
  deviceId: string,
  joinDate: string,
  // receiptCode: string,
  // image: string,
};

const CustomerBooths: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState(defaultDateValue);

  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  const { data: reportDataRes, isLoading: reportLoading } = useQuery(
    [
      'getCustomerBoothsService',
      currentPage,
      currentView,
      keyword,
      dateRange
    ],
    () => getCustomerBoothsService({
      keyword,
      page: currentPage,
      limit: currentView,
      joinedDateStart: dateRange[0],
      joinedDateEnd: dateRange[1],
    }),
    { enabled: !!currentPage }
  );

  // /* Variables */
  const columns: ColumnsType<ReportDataTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 60,
      fixed: 'left',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      width: 160,
      fixed: 'left',
    },
    {
      title: t('project.startDate'),
      dataIndex: 'joinDate',
      key: 'joinDate',
      width: 120,
      render: (el: string) => (
        <Typography.Text style={{ fontSize: 12, color: '#000' }}>{el ? dayjs(el).format('DD/MM/YYYY') : ''}</Typography.Text>
      ),
    },
    {
      title: 'Device Id',
      dataIndex: 'deviceId',
      key: 'deviceId',
      width: 160,
    },
    {
      title: t('system.boothName'),
      dataIndex: 'boothName',
      key: 'boothName',
      children: reportDataRes?.data?.booths?.map((item) => ({
        title: item.name,
        dataIndex: `booth-${item.id}`,
        key: `booth-${item.id}`,
        align: 'center',
        width: 120,
        render: (el: string) => (
          <Typography.Text style={{ fontSize: 14, color: '#000' }}>{el || '0'}</Typography.Text>
        ),
      })),
    },
    {
      title: t('system.image'),
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: t('gift.ipadLuckyDraw'),
      dataIndex: 'ipadLuckyDraw',
      key: 'ipadLuckyDraw',
      children: reportDataRes?.data?.gifts?.filter((el) => el.type === 'ipadLuckyDraw').map((item) => ({
        title: item.name,
        dataIndex: `gift-${item.id}`,
        key: `gift-${item.id}`,
        align: 'center',
        className: 'col',
        width: 120,
        render: (el: string) => (
          <Typography.Text style={{ fontSize: 14, color: '#000' }}>{el || '0'}</Typography.Text>
        ),
      })),
    },
    {
      title: t('gift.spLuckyDraw'),
      dataIndex: 'spLuckyDraw',
      key: 'spLuckyDraw',
      children: reportDataRes?.data?.gifts?.filter((el) => el.type === 'spLuckyDraw').map((item) => ({
        title: item.name,
        dataIndex: `gift-${item.id}`,
        key: `gift-${item.id}`,
        align: 'center',
        width: 120,
        className: 'col',
        render: (el: string) => (
          <Typography.Text style={{ fontSize: 14, color: '#000' }}>{el || '0'}</Typography.Text>
        ),
      })),
    },
    {
      title: t('gift.isNull'),
      dataIndex: 'isNull',
      key: 'isNull',
      width: 120,
      render: (el: string) => (
        <Typography.Text style={{ fontSize: 14, color: '#000' }}>{el || '0'}</Typography.Text>
      ),
    },
  ]), [reportDataRes, t]);

  const dataTable = useMemo(() => {
    if (reportDataRes?.data?.items && reportDataRes?.data?.items?.length > 0) {
      const res = reportDataRes?.data?.items?.map((item, idx) => {
        const tempBooth = {
          ...item?.booths.reduce((prev, curr) => ({
            ...prev,
            [`booth-${curr.id}`]: String(curr.turns),
          }), {}),
        };
        const tempGifts = {
          ...item?.gifts.reduce((prev, curr) => ({
            ...prev,
            [`${curr.id ? `gift-${curr.id}` : 'isNull'}`]: String(curr.quantity),
          }), {}),
        };
        return {
          no: String(idx + 1),
          id: item.customer.id,
          name: item.customer.fullName,
          deviceId: item.customer.phone,
          joinDate: item.joinedDate,
          ...tempBooth,
          ...tempGifts,
        };
      });
      return res;
    }
    return [];
  }, [reportDataRes]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: exportMutate, isLoading } = useMutation(
    ['export-report-customer-booths', keyword, dateRange],
    () => exportReportService({
      keyword,
      joinedDateStart: dateRange[0],
      joinedDateEnd: dateRange[1],
    }),
    {
      onSuccess: (res) => {
        if (res.data.link) {
          message.success(t('message.exportSuccess'));
          downloadFile(res.data.link);
        }
      },
      onError: () => {
        message.success(t('message.exportFail'));
      }
    }
  );

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('system.reportCustomer')}
        rightHeader={(
          <Button type="primary" onClick={() => exportMutate()} loading={isLoading}>
            <ExportOutlined />
            {t('system.export')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper p-reportCustomer">
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <RangePicker
            style={{ width: '100%' }}
            onChange={(val) => {
              if (!val) return;
              const res = val.map((item, idx) => {
                if (idx === 1) {
                  return `${formatDateYYYYMMDD(item)}`;
                }
                return formatDateYYYYMMDD(item);
              });
              setDateRange(res);
            }}
          />
        </Space>
        <Spin spinning={reportLoading}>
          <PageTable
            scrollY="70vh"
            scrollX={1600}
            handleSearch={setKeyword}
            isLoading={isLoading}
            bordered
            tableProps={{
              initShowColumns: [
                'id',
                'name',
                'deviceId',
                'boothName',
                'joinDate',
                'ipadLuckyDraw',
                'spLuckyDraw',
                'isNull',
              ],
              columns,
              pageData: dataTable,
              currentPage,
              pageSize: currentView,
              handleSetCurrentPage,
              handleSetCurrentView,
              total: reportDataRes?.meta.total || 1,
              noDeleteLanguage: true,
              noBaseCol: true
            }}
          />
        </Spin>
      </div>
    </>
  );
};

export default CustomerBooths;

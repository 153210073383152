import {
  SalePersonDataTypes, SalePersonParamTypes, SalePersonRequestTypes, UpdateSalePersonRequestTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getSalePersonService = async (params: SalePersonParamTypes):
  Promise<APIPaginationResponse<SalePersonDataTypes[]>> => {
  const res = await axiosInstance.get('salespersons', { params });
  return res.data;
};

export const getSalePersonByIdService = async (id: number):
  Promise<{ data: SalePersonDataTypes }> => {
  const res = await axiosInstance.get(`salespersons/${id}`);
  return res.data;
};

export const createSalePersonService = async (data: SalePersonRequestTypes):
  Promise<{ data: { id: number } }> => {
  const res = await axiosInstance.post('salespersons', data);
  return res.data;
};

export const updateSalePersonService = async (
  params: { id: number, data: UpdateSalePersonRequestTypes }
): Promise<void> => {
  await axiosInstance.put(`salespersons/${params.id}`, params.data);
};

export const updatePasswordSalePersonService = async (
  params: { id: number, data: { password: string } }
): Promise<void> => {
  await axiosInstance.put(`salespersons/${params.id}/change-password`, params.data);
};

import { CustomerBoothsTypes, FilterParamTypes } from './types';

import axiosInstance from 'common/services/common/instance';

export const getCustomerBoothsService = async (params?: FilterParamTypes):
  Promise<APIPaginationResponse<CustomerBoothsTypes>> => {
  const res = await axiosInstance.get('reports/customer-booths', { params });
  return res.data;
};

export const exportReportService = async (params?: FilterParamTypes):
  Promise<{ data: { link: string } }> => {
  const res = await axiosInstance.post('reports/customer-booths', null, { params });
  return res.data;
};

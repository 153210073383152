/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  message,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useRef,
} from 'react';
import {
  Controller, FormProvider, useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  ColumnHeaderTitle,
  convertPermissionAllDataTable,
  convertPermissionData,
  convertPermissionDataForm,
  convertPermissionWithModuleList,
  defaultPermissionWithModuleListVal,
  getPermissionColumnObj,
  RowCheckbox,
  tagRender,
} from './functions';
import { RoleDetailForm, RolePermissionProps } from './types';

import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import {
  createRoleService, getAllPermissionsService, getRoleService, updateRoleService
} from 'common/services/roles';
import { CreateRoleParams, UpdateRoleParams } from 'common/services/roles/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { removeItem } from 'common/utils/functions';
import { roleFormSchema } from 'common/utils/schemas';

const RoleDetail: React.FC<ActiveRoles> = () => {
  /* Hook */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idParam = useRef({
    id: Number(searchParams.get('id')),
    isValid: Number(searchParams.get('id') ?? -1) >= 0,
  });

  /* React-hook-form */
  const method = useForm<RoleDetailForm>({
    mode: 'onSubmit',
    resolver: yupResolver(roleFormSchema),
  });

  /* React-query */
  const {
    isLoading: permissionLoading,
    data: permissionData,
  } = useQuery(
    ['roleManagement-permissions'],
    () => getAllPermissionsService(),
    { keepPreviousData: true }
  );

  const {
    isFetching: detailLoading,
    data: detailData,
  } = useQuery(
    ['roleManagement-detail', idParam.current.id],
    () => getRoleService({ id: idParam.current.id }),
    { enabled: idParam.current.isValid }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['roleManagement-create'],
    async (params: CreateRoleParams) => createRoleService(params),
    {
      onSuccess: () => {
        message.success('Tạo thành công!');
        queryClient.invalidateQueries(['roleManagement-list']);
        method.reset();
        navigate(`${ROUTE_PATHS.ROLES_MANAGEMENT}`);
      },
      onError: () => {
        message.error('Đã có lỗi xảy ra! Vui lòng thử lại sau');
      }

    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['roleManagement-update'],
    async (params: UpdateRoleParams) => updateRoleService(params),
    {
      onSuccess: () => {
        message.success('Cập nhật thành công!');
        queryClient.invalidateQueries(['roleManagement-list']);
        method.reset();
        navigate(`${ROUTE_PATHS.ROLES_MANAGEMENT}`);
      },
      onError: () => {
        message.error('Đã có lỗi xảy ra! Vui lòng thử lại sau');
      }

    }
  );

  /* Datas */
  const generalPermissionData = useMemo(() => {
    if (permissionData) {
      return convertPermissionAllDataTable(permissionData);
    }
    return {
      tableData: undefined,
      permissionWithModuleList: undefined,
    };
  }, [permissionData]);

  /* Functions */
  const handleSubmit = (data: RoleDetailForm) => {
    if (idParam.current.isValid) {
      updateMutate({
        id: idParam.current.id,
        display_name: data.displayName,
        permissions: convertPermissionData(data.permissions),
      });
    } else {
      createMutate({
        display_name: data.displayName,
        permissions: convertPermissionData(data.permissions),
      });
    }
  };

  /* Table */
  const columns: ColumnsType<RolePermissionProps> = useMemo(() => {
    if (!generalPermissionData.permissionWithModuleList) {
      return [];
    }

    return ([
      {
        title: () => null,
        dataIndex: 'module',
        key: 'module',
        width: 200,
        render: (_name) => (
          <RowCheckbox
            module={_name}
            moduleForm={generalPermissionData.moduleForm}
          />
        )
      },
      getPermissionColumnObj('index', t('role.index'), generalPermissionData.permissionWithModuleList),
      getPermissionColumnObj('store', t('role.store'), generalPermissionData.permissionWithModuleList),
      getPermissionColumnObj('update', t('role.update'), generalPermissionData.permissionWithModuleList),
      getPermissionColumnObj('destroy', t('role.destroy'), generalPermissionData.permissionWithModuleList),
      {
        title: (
          <ColumnHeaderTitle
            action="others"
            title={t('role.others')}
            permissionWithModuleList={generalPermissionData.permissionWithModuleList}
          />
        ),
        align: 'center',
        dataIndex: 'others',
        key: 'others',
        render: (_name, _data) => (!_data.others?.length ? null : (
          <Controller
            name="permissionWithModuleList[others]"
            defaultValue={false}
            render={({
              field: { value: permissionModulesValue, onChange: permissionModulesOnChange },
            }) => (
              <Controller
                name={`permissions[${_data.module}][others]`}
                defaultValue={[]}
                render={({
                  field: { value, onChange },
                }) => (
                  <Select
                    mode="multiple"
                    size="middle"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={value}
                    onChange={(selectedValue: string[]) => {
                      onChange(selectedValue);
                      //* Filter duplicate modules then add to permissionWithModuleList
                      if (!selectedValue.length) {
                        return;
                      }
                      const newList = [...selectedValue].filter(
                        (ele) => ![...(permissionModulesValue || [])].includes(ele)
                      );

                      permissionModulesOnChange([...permissionModulesValue, ...newList]);
                    }}
                    onDeselect={(deSelectedValue: string) => {
                      const newArray = removeItem(permissionModulesValue, deSelectedValue);
                      permissionModulesOnChange(newArray);
                    }}
                    tagRender={tagRender}
                  >
                    {
                      _data.others?.map((val, idx) => (
                        <Select.Option value={val} key={`${_name}-option-${idx.toString()}`}>
                          {t(`role.${val.split('.')?.[1] || ''}`)}
                        </Select.Option>
                      ))
                    }
                  </Select>
                )}
              />
            )}
          />
        )),
      },
    ]);
  }, [t, generalPermissionData]);

  /* Effects */
  useEffect(() => {
    if (idParam.current.isValid && detailData) {
      //* Edit, detail role management
      const formData = convertPermissionDataForm(detailData.assignedPermissions);
      const permissionModules = convertPermissionWithModuleList(
        detailData.assignedPermissions
      );

      method.reset({
        displayName: detailData.role.displayName,
        permissions: formData,
        permissionWithModuleList: permissionModules
      });
    } else {
      //* New role management
      method.reset({
        permissionWithModuleList: defaultPermissionWithModuleListVal
      });
    }
  }, [detailData, method]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.rolesDetail')}
        rightHeader={(
          <Space>
            <Button
              type="primary"
              onClick={() => method.handleSubmit(handleSubmit)()}
            >
              <SaveOutlined />
              {' '}
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper" style={{ marginTop: 0 }}>
        <FormProvider<RoleDetailForm> {...method}>
          <form noValidate className="p-roleDetail_form">
            <div className="p-roleDetail_input">
              <Typography.Text strong>
                {t('system.name')}
                {' '}
              </Typography.Text>
              <Controller
                name="displayName"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    className="u-mt-8"
                    name="title"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </div>
            {generalPermissionData.tableData && (
              <div className="p-roleDetail_table">
                <Table
                  className="u-mt-24 p-roleDetail_table_content"
                  loading={permissionLoading || detailLoading || createLoading || updateLoading}
                  columns={columns}
                  dataSource={generalPermissionData.tableData}
                  pagination={false}
                  rowClassName="role-table-cell"
                  rowKey={(record) => record.module}
                  sticky
                />
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default RoleDetail;

import {
  IpadDataTypes, IpadParamTypes, IpadRequestTypes, UpdateIpadRequestTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getIpadService = async (params: IpadParamTypes):
  Promise<APIPaginationResponse<IpadDataTypes[]>> => {
  const res = await axiosInstance.get('ipads', { params });
  return res.data;
};

export const getIpadByIdService = async (id: number):
  Promise<{ data: IpadDataTypes }> => {
  const res = await axiosInstance.get(`ipads/${id}`);
  return res.data;
};

export const createIpadService = async (data: IpadRequestTypes):
  Promise<{ data: { id: number } }> => {
  const res = await axiosInstance.post('ipads', data);
  return res.data;
};

export const updateIpadService = async (
  params: { id: number, data: UpdateIpadRequestTypes }
): Promise<void> => {
  await axiosInstance.put(`ipads/${params.id}`, params.data);
};

export const updatePasswordIpadService = async (
  params: { id: number, data: { password: string } }
): Promise<void> => {
  await axiosInstance.put(`ipads/${params.id}/change-password`, params.data);
};

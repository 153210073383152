import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import SelectFile from 'common/components/SelectFile';
import {
  createGiftService,
  getGiftByIdService,
  updateGiftService
} from 'common/services/gift';
import { CreateGiftParams } from 'common/services/gift/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { editGiftData } from 'common/utils/schemas';

type GiftFormTypes = {
  name: string,
  thumbnail: string,
  active: boolean,
  image: string,
  quotaPerDay: number,
  price: number,
  probability: number,
  type: string,
  displayOrder: number,
};

const EditGift: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  const giftType = useMemo(() => [
    {
      value: 'ipadLuckyDraw',
      label: t('gift.ipadLuckyDraw'),
    },
    {
      value: 'spLuckyDraw',
      label: t('gift.spLuckyDraw'),
    }
  ], [t]);

  /* Hook form */
  const mainMethod = useForm<GiftFormTypes>({
    resolver: yupResolver(editGiftData),
    mode: 'onChange',
    defaultValues: {
      name: '',
      thumbnail: '',
      active: false,
      image: '',
      quotaPerDay: 0,
      price: 0,
      probability: 0,
      type: '',
      displayOrder: 0,
    }
  });

  /* Queries */
  const { data: giftData, isFetching: giftFetching } = useQuery(
    ['getGiftByIdService', idParam],
    () => getGiftByIdService({ id: idParam }),
    {
      enabled: !!idParam,
      onSuccess: (res) => {
        mainMethod.reset({
          name: res.giftData?.name || '',
          thumbnail: res.giftData?.thumbnail || '',
          active: res.giftData?.active || false,
          image: res.giftData?.image || '',
          quotaPerDay: res.giftData?.quotaPerDay || 0,
          price: res.giftData?.price || 0,
          probability: res.giftData?.probability || 0,
          type: res.giftData?.type || '',
          displayOrder: res.giftData?.displayOrder || 0,
        });
      }
    }
  );

  const { mutate: createGiftMutate, isLoading: createLoading } = useMutation(
    ['createGift'],
    createGiftService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.GIFT_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateGiftMutate, isLoading: updateLoading } = useMutation(
    ['updateGift'],
    (data: { id: number, params: CreateGiftParams }) => updateGiftService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );
  /* Variables */
  const handleSubmit = async (mainData: GiftFormTypes) => {
    const dataSubmit: CreateGiftParams = {
      name: mainData.name,
      thumbnail: mainData.thumbnail || '',
      active: mainData.active || false,
      image: mainData.image || '',
      quotaPerDay: mainData.quotaPerDay || 0,
      price: mainData.price || 0,
      probability: mainData.probability || 0,
      type: mainData.type || '',
      displayOrder: mainData.displayOrder || 0,
    };

    if (idParam) {
      updateGiftMutate({
        id: idParam,
        params: dataSubmit
      });
    } else {
      createGiftMutate(dataSubmit);
    }
  };

  /* Render */
  return (
    <div className="p-editGift">
      <HeaderPage
        fixed
        title={idParam ? `${t('gift.editGift')}` : `${t('gift.addGift')}`}
        rightHeader={(
          <Button
            type="primary"
            disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
            onClick={mainMethod.handleSubmit(handleSubmit)}
            loading={createLoading || updateLoading}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            {giftFetching ? <Spin className="center-absolute" size="large" spinning />
              : (
                <Card
                  type="inner"
                >
                  <FormProvider {...mainMethod}>
                    <Space direction="vertical" size={12} style={{ width: '100%' }}>
                      <Row gutter={16}>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('gift.name')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="name"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  placeholder={t('game.name')}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('gift.price')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="price"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  placeholder={t('system.code')}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={error?.message}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('system.probability')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="probability"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  placeholder={t('system.probability')}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={error?.message}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('system.quotaPerDay')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="quotaPerDay"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  placeholder={t('system.quotaPerDay')}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={error?.message}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('project.giftType')}
                              {' '}
                            </Typography.Text>
                            <Controller
                              name="type"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                              }) => (
                                <Select
                                  className="u-mt-8"
                                  size="middle"
                                  style={{ width: '100%' }}
                                  placeholder="---"
                                  value={value}
                                  onChange={onChange}
                                >
                                  {
                                    giftType.map((val, idx) => (
                                      <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                                        {val.label}
                                      </Select.Option>
                                    ))
                                  }
                                </Select>
                              )}
                            />
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="u-mt-8">
                            <Typography.Text strong>
                              {t('system.displayOrder')}
                            </Typography.Text>
                            {' '}
                            <Controller
                              name="displayOrder"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  type="number"
                                  placeholder={t('system.displayOrder')}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="middle"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Controller
                              name="image"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <>
                                  <SelectFile
                                    title={t('system.image')}
                                    value={value}
                                    name="image"
                                    handleSelect={(url) => onChange(url)}
                                    handleDelete={() => onChange(undefined)}
                                  />
                                  {error && (
                                    <span className="a-input_errorMessage">
                                      {error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </Col>
                        <Col md={12} sm={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Controller
                              name="thumbnail"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <>
                                  <SelectFile
                                    title={t('system.thumbnail')}
                                    value={value}
                                    name="thumbnail"
                                    handleSelect={(url) => onChange(url)}
                                    handleDelete={() => onChange(undefined)}
                                  />
                                  {error && (
                                    <span className="a-input_errorMessage">
                                      {error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="u-mt-8">
                            <Typography.Text strong>
                              {t('system.status')}
                            </Typography.Text>
                            {' '}
                            <div className="u-mt-8">
                              <Controller
                                name="active"
                                control={mainMethod.control}
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Space>
                  </FormProvider>
                </Card>
              )}
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={giftData ? dayjs(giftData?.giftData.createdAt).fromNow() : ''}
              createdBy=""
              lastUpdated={giftData ? dayjs(giftData?.giftData.updatedAt).fromNow() : ''}
              lastUpdatedBy=""
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditGift;
